import _extends from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends.js";
import _objectWithoutProperties from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/objectWithoutProperties.js";
var _excluded = ["FallbackComponent", "children"];
import React from "react";
import { ErrorBoundary as ReactErrorBoundary } from "react-error-boundary";
import loggy from "@catchoftheday/loggy";
import { jsx as ___EmotionJSX } from "@emotion/react";
export var ErrorBoundary = function ErrorBoundary(_ref) {
  var FallbackComponent = _ref.FallbackComponent,
      children = _ref.children,
      props = _objectWithoutProperties(_ref, _excluded);

  var defaultFallbackComponent = function defaultFallbackComponent() {
    return null;
  };

  return ___EmotionJSX(ReactErrorBoundary, _extends({
    onError: function onError(e, _ref2) {
      var componentStack = _ref2.componentStack;
      loggy.error(e, {
        level: "fatal",
        contexts: {
          react: {
            componentStack: componentStack
          }
        }
      });
    },
    FallbackComponent: FallbackComponent || defaultFallbackComponent
  }, props), children);
};