import React from "react";
import { AppExperimentProvider } from "@catchoftheday/analytics";
import { ErrorBoundary } from "@catchoftheday/cg-components";
import { Forter } from "@catchoftheday/common-vendors";
import { Header, LegacySnowplowConfig } from "@catchoftheday/header";
import { jsx as ___EmotionJSX } from "@emotion/react";

var Index = function Index() {
  // We're extracting the host from the window object here as we cannot use withHost HOC in the static build
  var hostOnBrowser = true ? window.location.host : "";

  var renderHeader = function renderHeader() {
    return ___EmotionJSX(AppExperimentProvider, null, ___EmotionJSX(Header, {
      ssrMegaMenu: false,
      host: hostOnBrowser
    }));
  };

  return ___EmotionJSX(ErrorBoundary, {
    FallbackComponent: function FallbackComponent() {
      return renderHeader();
    }
  }, ___EmotionJSX(LegacySnowplowConfig, {
    domain: hostOnBrowser
  }), renderHeader(), ___EmotionJSX(Forter, null));
};

export default Index;